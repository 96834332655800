/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { GlobalProvider } from './src/components/global-context';

export const wrapPageElement = ({ element }) => (
  <GlobalProvider><AnimatePresence mode='wait'>{ element }</AnimatePresence></GlobalProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {

  const transitionDelay = 600;
  window.history.scrollRestoration = "manual";

  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  setTimeout(() => {
    window.scrollTo(...currentPosition);
  }, transitionDelay);

  return false;
};
